import { ClientJS } from 'clientjs';

export const client = new ClientJS();
/*
//BROWSER
console.log('%cBROWSER','background: #FF0000; color: #ffffff')
console.log('Browser: ' + client.getBrowser())
console.log('Browser Major Version: ' + client.getBrowserMajorVersion())
console.log('Browser Version: ' + client.getBrowserVersion())
console.log('Chrome: ' + client.isChrome())
console.log('Firefox: ' + client.isFirefox())
console.log('IE: ' + client.isIE())
console.log('Opera: ' + client.isIE())
console.log('Safari: ' + client.isSafari())
console.log('Mobile Safari: ' + client.isMobileSafari())
console.log('Mobile Windows: ' + client.isMobileWindows())

//OS
console.log('%cOPERATING SYSTEM','background: #FF0000; color: #ffffff')
console.log('OS: ' + client.getOS())
console.log('OS Version: ' + client.getOSVersion())
console.log('Ubuntu: ' + client.isUbuntu())
console.log('MacOS: ' + client.isMac())
console.log('Windows: ' + client.isWindows())
console.log('Solaris: ' + client.isSolaris())
console.log('Linux: ' + client.isLinux())
console.log('Java: ' + client.isJava())

//DEVICE
console.log('%cDEVICE','background: #FF0000; color: #ffffff')
console.log('DeviceType: ' + client.getDeviceType())
console.log('Device: ' + client.getDevice())
console.log('Ipad: ' + client.isIpad())
console.log('IPhone: ' + client.isIphone())
console.log('Ipod: ' + client.isIpod())

//MOBILE
console.log('%cMOBILE','background: #FF0000; color: #ffffff')
console.log('Mobile: ' + client.isMobile())
console.log('Mobile Major: ' + client.isMobileMajor())
console.log('Mobile IOS: ' + client.isMobileIOS())
console.log('Mobile Android: ' + client.isMobileAndroid())
console.log('Mobile BlackBerry: ' + client.isMobileBlackBerry())

//OTHER
console.log('%cMISC','background: #FF0000; color: #ffffff')
console.log('Language: ' + client.getLanguage())
console.log('UserAgent: ' + client.getUserAgent())
console.log('Current Resolution: ' + client.getCurrentResolution())
console.log('Color Depth: ' + client.getColorDepth())
*/