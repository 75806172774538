console.log('facebook.js loaded...')


import Logo from './images/logo.png';
import './facebook.css';
import { client } from './client';


export function startfacebook(){
    $('body').append('<div id="facebook"></div>');

    $('#facebook').append('<img id="facebook-logo" src="' + Logo + '">')
    $('#facebook').append('<h3>Welcome to Getgirls</h3>')
    $('#facebook').append('<h5>You have started GetGirls in Facebook</h5>')
    $('#facebook').append('<div>This will not work well<br>We need to transfer you to a real browser</div>')

    if (client.isMobileIOS()){
        $('#facebook').append('<div style="font-size:24px">Push<br><iconify-icon icon="mingcute:upload-line"></iconify-icon><br>Select Open in Safari</div>')
    }else if (client.isMobileAndroid()){
        $('#facebook').append('<div style="font-size:24px">Push<br><iconify-icon icon="pepicons-pop:dots-y"></iconify-icon><br>in the upper right corner<br>then select<br><iconify-icon icon="iconamoon:enter"></iconify-icon> Open in Chrome</div>')
    }else{
        if (client.isWindows()){
            $('#facebook').append('<div style="font-size:40px">On<br><iconify-icon icon="devicon:windows11"></iconify-icon><br>We suggest to use<br><iconify-icon icon="logos:chrome"></iconify-icon> or <iconify-icon icon="logos:microsoft-edge"></iconify-icon></div>')
        }else if (client.isMac()){
            $('#facebook').append('<div style="font-size:40px">On<br><iconify-icon icon="wpf:macos"></iconify-icon><br>We suggest to use<br><iconify-icon icon="logos:safari"></iconify-icon></div>')
        }else{
            $('#facebook').append('<div style="font-size:40px">Please open<br>your favourite browser')
        }

        $('#facebook').append('<div>Once your browser is open<br>Enter "getgirls.app" in the address field</div>')
    }

    $('#facebook').append('<div style="font-size:small;color:lightgray">' + BUILD_INFO.time + '</div>')
}
