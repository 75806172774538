console.log('firebase.js loaded...')

import { getAuth, RecaptchaVerifier , OAuthProvider, signInWithPopup, sendPasswordResetEmail, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword,signInWithPhoneNumber, fetchSignInMethodsForEmail, linkWithCredential, getAdditionalUserInfo, signInWithCredential, linkWithPhoneNumber, PhoneAuthProvider, linkWithPopup, updateProfile} from "firebase/auth";
import { doc, initializeFirestore, persistentLocalCache, persistentMultipleTabManager, updateDoc} from "firebase/firestore"; 
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent  } from "firebase/analytics";


import './firebase.css';

const firebaseConfig = {
  apiKey: "AIzaSyCdxqyVSSwckWrvJcyiAOmg4VFZgt3di2A",
  authDomain: "getgirls-today.firebaseapp.com",
  projectId: "getgirls-today",
  storageBucket: "getgirls-today.appspot.com",
  messagingSenderId: "793192522926",
  appId: "1:793192522926:web:e9d0a249a3c625cc3a793f",
  measurementId: "G-RBJFLGWBC1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LczDegmAAAAACrzyW372fP3omDwwVfzwUTwdP56'),
      isTokenAutoRefreshEnabled: true
});

export const auth = getAuth(app);

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({tabManager: persistentMultipleTabManager()})
});

export const storage = getStorage()
export const messaging = getMessaging(app);

export function logAnalytics(event, params){
    logEvent(analytics, event, params);
}


const PROVIDERS = [
    'google.com',
    'facebook.com',
    'password'
]

var thecallback = null;
export function doSignIn(callback){
  thecallback = callback
  Logon();
}
export function doLink(callback){
    thecallback = callback
    if (auth.currentUser.email){
        fetchSignInMethodsForEmail(auth,auth.currentUser.email)
        .then(function(methods){
            console.log(methods)
            Link(methods);
        })
    } else Link([])
}

function Logon(){
    $('body').append('<div class="modal" id="signin"></div>');{
        $('#signin').append('<div class="modal-dialog modal-dialog-centered" id="signin-top"></div>');
        $('#signin-top').append('<div class="modal-content" id="signin-content"></div>')

        $('#signin-content').append('<div class="modal-header centered-header" id="signin-header"></div>')
        $('#signin-content').append('<div class="modal-body" id="signin-body"></div>')

        $('#signin-header').append('<div class="modal-title" id="signin-title">Sign In to your account</div>')
        $('#signin-header').append('<button id="signin-close-button" type="button" class="btn-close" aria-label="Close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>');

        $('#signin-body').append('<button id="googlebutton" type="button" class="btn signin-button"><iconify-icon icon="flat-color-icons:google" class="signin-logo"></iconify-icon> Use Google</button>')
        //$('#signin-body').append('<button id="facebookbutton" type="button" class="btn signin-button"><iconify-icon icon="logos:facebook" class="signin-logo"></iconify-icon> Use Facebook</button>')
        $('#signin-body').append('<button id="mailbutton" type="button" class="btn signin-button"><iconify-icon icon="ic:outline-email" style="color: maroon;" class="signin-logo"></iconify-icon> Use Email</button>')

        $('#signin-close-button').on('click',function(event) {
            $('#signin').modal('hide');
            thecallback();
        });

        $('#signin').on('shown.bs.modal', function(){
        })
        $('#signin').on('hidden.bs.modal', function(){
            $('#signin').remove();
        })

        function getCredential(error){
            switch (error.customData._tokenResponse.providerId){
                case PROVIDERS[0]:
                    return GoogleAuthProvider.credentialFromError(error);
                case PROVIDERS[1]:
                    return FacebookAuthProvider.credentialFromError(error);
            }
            return null;
        }
        
        function login(provider){
            signInWithPopup(auth, provider)
            .then((result) => {
                $('#signin').modal('hide');
                thecallback();
            }).catch((error) => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    const email = error.customData.email;
                    const credential = getCredential(error);
                    $('#signin').modal('hide');
                    doMerge(email,credential);               
                }else{
                    console.error('Login error', error.message)
                    $('#signin-content').append('div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">Logon Failed<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');
                }
            });    
        }

        $('#mailbutton').on('click',function(){
            DoMailPassword('signin',"",null)
            $('#signin').modal('hide');
        })
        $('#googlebutton').on('click', function(){
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            login(provider);
        })
        $('#facebookbutton').on('click',function(){
            $('#signin-content').append('<div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">Facebook option not available yet<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');

            return;
            const provider = new FacebookAuthProvider();
            provider.addScope('public_profile');
            provider.addScope('email');
            provider.setCustomParameters({
                'display': 'popup'
            });
            login(provider);
        })
    }
    $('#signin').modal('show');

}

function Link(methods){
    $('body').append('<div class="modal" id="link"></div>');{
        $('#link').append('<div class="modal-dialog modal-dialog-centered" id="link-top"></div>');
        $('#link-top').append('<div class="modal-content" id="link-content"></div>')

        $('#link-content').append('<div class="modal-header centered-header" id="link-header"></div>')
        $('#link-content').append('<div class="modal-body" id="link-body"></div>')

        $('#link-header').append('<div class="modal-title" id="link-title">Add a new signin to your account</div>')
        $('#link-header').append('<button id="link-close-button" type="button" class="btn-close" aria-label="Close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>');

        if (!methods.includes(PROVIDERS[0])) $('#link-body').append('<button id="googlebutton" type="button" class="btn link-button"><iconify-icon icon="flat-color-icons:google" class="link-logo"></iconify-icon> Use Google</button>')
        if (!methods.includes(PROVIDERS[1])) $('#link-body').append('<button id="facebookbutton" type="button" class="btn link-button"><iconify-icon icon="logos:facebook" class="link-logo"></iconify-icon> Use Facebook</button>')
        if (!methods.includes(PROVIDERS[2])) $('#link-body').append('<button id="mailbutton" type="button" class="btn link-button"><iconify-icon icon="ic:outline-email" style="color: maroon;" class="link-logo"></iconify-icon> Use Email</button>')
        if (methods.length == PROVIDERS.length) $('#link-body').append('<h3>No more logon options available</h3>')

        $('#link-close-button').on('click',function(event) {
            $('#link').modal('hide');
            thecallback();
        });

        $('#link').on('shown.bs.modal', function(){
        })
        $('#link').on('hidden.bs.modal', function(){
            $('#link').remove();
        })

        function getCredential(error){
            switch (error.customData._tokenResponse.providerId){
                case PROVIDERS[0]:
                    return GoogleAuthProvider.credentialFromError(error);
                case PROVIDERS[1]:
                    return FacebookAuthProvider.credentialFromError(error);
            }
            return null;
        }
        
        function login(provider){
            linkWithPopup(auth.currentUser, provider)
            .then((result) => {

                console.log('RESULT',result)
                $('#link').modal('hide');

                if (((auth.currentUser.displayName == null) && (result._tokenResponse.displayName != null)) || ((auth.currentUser.photoURL == null) && (result._tokenResponse.photoUrl != null))){
                    let data ={}
                    let usr = {
                        "personal.email": auth.currentUser.email,
                        "personal.anonymous": auth.currentUser.isAnonymous,
                        "personal.emailverified": auth.currentUser.emailVerified,
                        "personal.displayname": auth.currentUser.displayName,
                        "personal.photourl": auth.currentUser.photoURL
                    }
                    if ((auth.currentUser.displayName == null) && (result._tokenResponse.displayName != null)) {
                        data.displayName = result._tokenResponse.displayName;
                        usr["personal.displayname"] = result._tokenResponse.displayName;
                    }
                    if ((auth.currentUser.photoURL == null) && (result._tokenResponse.photoUrl != null)){
                        data.photoURL = result._tokenResponse.photoUrl
                        usr["personal.photourl"] = result._tokenResponse.photoUrl;
                    }


                    updateProfile(auth.currentUser, data)
                    .then(function(){
                        console.log('Profile updated', data)
                        return updateDoc(doc(db,'users',auth.currentUser.uid), usr)
                    })
                    .then(function(){
                        console.log('database updated',usr)
                        thecallback();
                    })
                    .catch(error => {
                        console.error('Updating profile: ' + error)
                        thecallback();
                    })

                    console.log('we should update user')
                }

            }).catch((error) => {
                const errorMessage = error.message;
                console.error('Linking with Popup: ' + errorMessage, error)
                $('#link-content').append('<div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">Logon Failed<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');
            });
        }

        $('#mailbutton').on('click',function(){
            let email = "";
            if (auth.currentUser.email) email = auth.currentUser.email;
            DoMailPassword('link',email,null)
            $('#link').modal('hide');
        })
        $('#googlebutton').on('click', function(){
            let provider = new GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            login(provider);
        })
        $('#facebookbutton').on('click',function(){
            $('#link-content').append('<div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">Facebook option not available yet<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');
            return;
            const provider = new FacebookAuthProvider();
            provider.addScope('public_profile');
            provider.addScope('email');
            provider.setCustomParameters({
                'display': 'popup'
            });
            login(provider);
        })
    }
    $('#link').modal('show');

}

function DoMailPassword(mode,email,credential=null){

    function createMailPasswordDialog(){
        $('body').append('<div class="modal" id="mailpassword"></div>');{
            $('#mailpassword').append('<div class="modal-dialog modal-dialog-centered" id="mailpassword-top"></div>');
            $('#mailpassword-top').append('<div class="modal-content" id="mailpassword-content"></div>')
    
            $('#mailpassword-content').append('<div class="modal-header centered-header" id="mailpassword-header"></div>')
            $('#mailpassword-content').append('<form id="mailpassword-body" class="modal-body row g-2 needs-validation" novalidate></form>')
            $('#mailpassword-content').append('<div class="modal-footer" id="mailpassword-footer"></div>')
    
            $('#mailpassword-header').append('<div id="mailpasswordtitle" class="modal-title">Provide email and password</div>')
            $('#mailpassword-header').append('<button id="mailpasswordclosebutton" type="button" class="btn-close" aria-label="Close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>');

            $('#mailpassword-body').append('<div id="mailpassword-row1" class="row g-2"></row>');{
                $('#mailpassword-row1').append('<div id= "mailpassword-row1-col1" class="form-floating col-md-12"></div>')
                    $('#mailpassword-row1-col1').append('<input type="email" class="form-control" id="mailpassword-email" required tabindex="1">')
                    $('#mailpassword-row1-col1').append('<label for="mailpassword-email">Email</label>');
                    $('#mailpassword-row1-col1').append('<div class="invalid-feedback">Illegal Email</div>')
            }
    
            $('#mailpassword-body').append('<div id="mailpassword-row2" class="row g-2"></row>');{
                $('#mailpassword-row2').append('<div id= "mailpassword-row2-col1" class="form-floating col-md-12"></div>')
                    $('#mailpassword-row2-col1').append('<input type="password"  minlength="6" required class="form-control" id="mailpassword-password" tabindex="2">')
                    $('#mailpassword-row2-col1').append('<label for="mailpassword-homepage">Password</label>');
                    $('#mailpassword-row2-col1').append('<div class="invalid-feedback">Illegal Password</div>')
            }
    
            $('#mailpassword-body').append('<button type="button" id="mailpassword-forgotpassword" class="hidden" tabindex="-1">I forgot my password</button>');
    
            $('#mailpassword-footer').append('<button type="button" id="mailpasswordCancelbutton" class="btn btn-secondary mailpassword-button" tabindex="4">Cancel</button>')
            $('#mailpassword-footer').append('<button type="button" id="mailpasswordOKbutton" class="btn btn-secondary mailpassword-button" tabindex="3">OK</button>')
    
            $('#mailpasswordclosebutton').on('click', function(event) {
                $('#mailpassword').modal('hide');
                thecallback()
            });
            $('#mailpasswordCancelbutton').on('click', function(event) {
                $('#mailpassword').modal('hide');
                thecallback()
            });
    
            $('#mailpassword-forgotpassword').on('click', function(evt) {
                let email = $('#mailpassword-email').val();
                //console.log(email)
                if (document.getElementById('mailpassword-email').checkValidity() && (email != "")){
                    sendPasswordResetEmail(auth, email)
                    .then(() => {
                        //tell user reset mail was send
                    })
                    .catch((error) => {
                        const errorMessage = error.message;
                        //tell user reset mail was NOT send
                    });
                    
                    
                }else{
                    $('#mailpassword-body').addClass('was-validated')
                }
    
                evt.preventDefault();
                evt.stopPropagation();
    
            })
    
            
    
            $('#mailpasswordOKbutton').on('click', function(event) {
                if (!document.getElementById('mailpassword-body').checkValidity() ){
                    event.preventDefault()
                    event.stopPropagation()
                    $('#mailpassword-body').addClass('was-validated')
                    return;
                }
    
                const email = $('#mailpassword-email').val()
                const password = $('#mailpassword-password').val()
    
                if (mode == 'signin'){
                    signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        // Signed in 
                        let promises = []
                        if (credential != null) promises.push(linkWithCredential(userCredential.user,credential));
                        return Promise.all(promises)
                    })
                    .then(results => {
                        $('#mailpassword').modal('hide');
                        thecallback();
                    })
                    .catch((error) => {
                        console.error('Linking with Email', error)
                        $('#mailpassword-content').append('<div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">Logon Failed<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>');
                    });
                }else if (mode == 'link'){
                    const credential = EmailAuthProvider.credential(email, password);
                    $('#mailpassword').modal('hide');
                    doMerge(email,credential)
                }else
                    console.warning('Using mailpassword dialog in illegal mode: ' + mode)
    
            });
            $('#mailpassword').on('shown.bs.modal', async function(){
                $('#mailpassword-body').removeClass('was-validated')
                $('#mailpassword-email').focus();
                const mode = $('#mailpassword').prop('mode')
                if (mode=='signin') $('#mailpassword-forgotpassword').removeClass('hidden')
    
            })
            $('#mailpassword').on('hidden.bs.modal', async function(){
                $('#mailpassword').remove();
            })
        }
    }
    
    createMailPasswordDialog();

    $('#mailpassword').prop('mode',mode);
    $('#mailpassword-email').val(email)
    $('#mailpassword-password').val("")
    $('#mailpassword-email').attr('disabled',email != "")
    $('#mailpassword').modal('show')
}

function doMerge(email, credential)
{
    function createMergeDialog(){
        $('body').append('<div class="modal" id="merge"></div>');{
            $('#merge').append('<div class="modal-dialog modal-dialog-centered" id="merge-top"></div>');
            $('#merge-top').append('<div class="modal-content" id="merge-content"></div>')
    
            $('#merge-content').append('<div class="modal-header centered-header" id="merge-header"></div>')
            $('#merge-content').append('<form id="merge-body" class="modal-body"></form>')
            $('#merge-content').append('<div class="modal-footer" id="merge-footer"></div>')
    
            $('#merge-header').append('<div class="modal-title">Merge Accounts?</div>')
            $('#merge-header').append('<button id="merge-close-button" type="button" class="btn-close" aria-label="Close"><iconify-icon icon="material-symbols:close"></iconify-icon></button>');
    
            $('#merge-body').append('<div>Merge your</div>')
            $('#merge-body').append('<div>' + getIcon(MethodsNew) + '</div>')
            $('#merge-body').append('<div>account into your</div>')
            $('#merge-body').append('<div>' + getIcon(MethodsOld) + '</div>')
            $('#merge-body').append('<div>account will require you to login to</div>')
            $('#merge-body').append('<div>' + getIcon(MethodsOld)  + '</div>')
            $('#merge-body').append('<div>Do you wish to continue?</div>')
    
            $('#merge-footer').append('<button type="button" id="merge-Cancelbutton" class="btn btn-secondary merge-button">Cancel</button>')
            $('#merge-footer').append('<button type="button" id="merge-OKbutton" class="btn btn-secondary merge-button">OK</button>')
    
            $('#merge-close-button').on('click',function(event) {
                $('#merge').modal('hide');
            });
            $('#merge-Cancelbutton').on('click', function(event) {
                $('#merge').modal('hide');
            });
    
            function getIcon(id){
                switch (id){
                    case PROVIDERS[0]:
                        return '<iconify-icon icon="flat-color-icons:google" class="merge-icon"></iconify-icon>';
                    case PROVIDERS[1]:
                        return '<iconify-icon icon="logos:facebook" class="merge-icon"></iconify-icon>';
                    case PROVIDERS[2]:
                        return '<iconify-icon icon="ic:outline-email" style="color: maroon;" class="merge-icon"></iconify-icon>';
                    }
            }
            function getProviderForProviderId(id){
                switch (id){
                    case PROVIDERS[0]:
                        return new GoogleAuthProvider();
                    case PROVIDERS[1]:
                        return new FacebookAuthProvider();
                }
                return null;
            }
            $('#merge-OKbutton').on('click', function(event) {            
                if (MethodsOld === PROVIDERS[2]) {
                    $('#merge').modal('hide');
                    DoMailPassword('signin',email,credential);
                }else{
                    var provider = getProviderForProviderId(MethodsOld);
                    
                    signInWithPopup(auth, provider)
                    .then(function(result) {
                        return linkWithCredential(result.user, credential)
                    })
                    .then(function(usercred) {
                        $('#merge').modal('hide');
                        thecallback();
                    })
                    .catch(error => {

                    })
                }
            })
    
            $('#merge').on('hidden.bs.modal', async function(){
                $('#merge').remove();
            })
        }
    }


    var MethodsOld;
    var MethodsNew = credential.signInMethod;

    fetchSignInMethodsForEmail(auth,email)
    .then(function(methods) {
        console.log(methods)
        MethodsOld = methods[0]
        console.log('MERGE', MethodsOld,MethodsNew)
        createMergeDialog();
        $('#merge').modal('show')
    })
    .catch(error => {
        console.error('Fething login methods for merge confirm failed: ' + error)
    })
}