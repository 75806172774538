// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.serviceworker-alert{
    position: fixed;
    width: calc(100% - 20px);
    left: 10px;
    bottom: 0px;
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:20px;
    z-index: 9999;
}

.serviceworker-alert-info{
    flex-grow:1;
}

.serviceworker-alert-button{
}`, "",{"version":3,"sources":["webpack://./src/serviceworker.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,QAAQ;IACR,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;AACA","sourcesContent":["\r\n\r\n.serviceworker-alert{\r\n    position: fixed;\r\n    width: calc(100% - 20px);\r\n    left: 10px;\r\n    bottom: 0px;\r\n    display:flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    gap:20px;\r\n    z-index: 9999;\r\n}\r\n\r\n.serviceworker-alert-info{\r\n    flex-grow:1;\r\n}\r\n\r\n.serviceworker-alert-button{\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
