console.log('index.js loaded...')
import $ from "jquery";
require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';
import 'iconify-icon';

import './index.css';
import Logo from './images/logo.png';

import { startfacebook } from "./facebook";
import { startinstallation, stopinstallation } from "./installation";
import { startapp, stopapp } from "./app";
import { client } from "./client";
import { installServiceWorker } from "./serviceworker";


$('body').append('<img id="body-logo" src="' + Logo + '">')
$('body').append('<div id="body-spinner" class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>')

window.addEventListener('DOMContentLoaded', function() {
    $('#body-spinner').addClass('hidden')

    if (process.env.NODE_ENV == 'development'){
        //startinstallation();
        installServiceWorker();
        startapp()
    }else{
        if (client.getBrowser() == 'Facebook'){
            startfacebook();
        }else{
            installServiceWorker();
            if (window.matchMedia('(display-mode: standalone)').matches) {
                startapp();
            }else{
                startinstallation()
            }
            window.matchMedia('(display-mode: standalone)').addEventListener('change', event => {
                if (event.matches) {
                    stopinstallation()
                    startapp()
                } else {
                    stopapp();
                    startinstallation()
                }
            });
        }
    }
});