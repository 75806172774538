console.log('installation.js started')

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { client } from './client';
import Logo from './images/logo.png';
import './installation.css';
import { db } from './firebase';


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlinvite = urlParams.get('invite')
console.log('URLINVITE', urlinvite)

export function startinstallation(){
    console.log('Start invitation')
    $('body').append('<div id="installation"></div>');

    $('#installation').append('<img id="installation-logo" src="' + Logo + '">')
    $('#installation').append('<h3>Welcome to Getgirls</h3>')
    $('#installation').append('<h5>First thing to do is to install</h5>')


    if (client.isChrome()){
        if (client.isMobile()){
            if (client.isMobileAndroid())
                $('#installation').append('<div style="font-size:24px">On<br><iconify-icon icon="logos:chrome"></iconify-icon><br>Go to<br><iconify-icon icon="mdi:dots-vertical"></iconify-icon><br>In upper right corner<br>Select<br><iconify-icon icon="material-symbols:install-mobile"></iconify-icon> Install app</div>')
            else
                $('#installation').append('<div style="font-size:24px"><iconify-icon icon="logos:chrome"></iconify-icon><br>Does not support installation of GetGirls on this platform</div>')
        }
        else 
            $('#installation').append('<div style="font-size:40px">On<br><iconify-icon icon="logos:chrome"></iconify-icon><br>Clik<br><iconify-icon icon="material-symbols:install-desktop-rounded"></iconify-icon><br>in the URL bar</div>')

    }else if (client.isMobileSafari() || client.isSafari()){
        $('#installation').append('<div style="font-size:40px">On<br><iconify-icon icon="logos:safari"></iconify-icon><br>Clik<br><iconify-icon icon="octicon:share-24"></iconify-icon><br>Scroll and tap<br>Add to Home Screen <iconify-icon icon="mdi:plus-box-outline"></iconify-icon></div>')

    }else if (client.isFirefox()){
        $('#installation').append('<div style="font-size:40px">On<br><iconify-icon icon="logos:firefox"></iconify-icon><br>Clik<br><iconify-icon icon="material-symbols:install-desktop-rounded"></iconify-icon><br>in the URL bar</div>')
    }else if (client.isOpera()){
        $('#installation').append('<div style="font-size:40px"><iconify-icon icon="logos:opera"></iconify-icon><br>Does not support<br>installation of GetGirls</div>')
    }else if (client.isIE()){
        $('#installation').append('<div style="font-size:40px"><iconify-icon icon="devicon:ie10"></iconify-icon><br>Does not support<br>installation of GetGirls</div>')
    }else if (client.getBrowser() == "Edge"){
        $('#installation').append('<div style="font-size:40px">On<br><iconify-icon icon="logos:microsoft-edge"></iconify-icon><br>Clik<br><iconify-icon icon="charm:apps-plus"></iconify-icon><br>in the URL bar</div>')
    }else{
        $('#installation').append('<div style="font-size:40px">Not sure if and how to<br>install GetGirls from your browser<br>Please search for instruction on<br>Installation of PWA<br>or use one of the following<br><iconify-icon icon="logos:chrome"></iconify-icon> <iconify-icon icon="logos:safari"></iconify-icon> <iconify-icon icon="logos:microsoft-edge"></iconify-icon></div>')
    }

    $('#installation').append('<div style="font-size:small;color:lightgray">' + BUILD_INFO.time + '</div>')



    if (urlinvite){
        getDoc(doc(db, 'invites', urlinvite))
        .then(snapshot => {
            if (snapshot.exists()){
                const data= snapshot.data()
                if (data.account == false){
                    updateDoc(doc(db, 'invites', urlinvite),'account', true)
                    .then(function(){
                        console.log('Invite acknowledged: ' + urlinvite)
                    })
                    .catch(error => {
                        console.error('Setting account = true on invite failed: ' + error)
                    })
                }else
                    console.error('Invitation already acknowledged: ' + urlinvite)
            }else
                console.error('Invitation does not exists: ' + urlinvite)
        })
        .catch(error => {
            console.error('Extracting potential invite failed:' + error)
        })
    }
}

export function stopinstallation(){
    $('#installation').remove();
}