// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --default-color:#FE7FBA;
}


body {
    margin: 0px;
    background-color: white;
    color: black;
    font-size: medium;
    cursor: default;
    user-select: none;
    /*For the sake of IOS title bar color*/
    background-color: var(--default-color);
    /*For the sake of avoid IOS dragging of body*/
    position: fixed;
    width: 100%;
    height: 100%;
}

html, body{
    overflow:hidden;
}

/*CLASS'es*/
.hidden{
    display: none !important;
}

.timestamp{
    position: fixed;
    font-size: 15px;
    color: lightgray;
    width: 200px;
    height: 20px;
    line-height: 20px;
    text-align: end;
    left: calc(100% - 205px);
    top: calc(100% - 20px);
}

#body-logo{
    width: 250px;
    height: 250px;
    object-fit: contain;
    position: absolute;
    left: calc(50% - 125px);
    top: calc(50% - 125px);
    z-index: -1;
}

#body-spinner{
    position: absolute;

    top: calc(50% - 160px);
    left: calc(50% - 160px);
    width: 320px;
    height: 320px;
    color: black;
    border-width: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,sCAAsC;IACtC,sCAAsC;IACtC,6CAA6C;IAC7C,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA,WAAW;AACX;IACI,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,kBAAkB;;IAElB,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[":root {\r\n    --default-color:#FE7FBA;\r\n}\r\n\r\n\r\nbody {\r\n    margin: 0px;\r\n    background-color: white;\r\n    color: black;\r\n    font-size: medium;\r\n    cursor: default;\r\n    user-select: none;\r\n    /*For the sake of IOS title bar color*/\r\n    background-color: var(--default-color);\r\n    /*For the sake of avoid IOS dragging of body*/\r\n    position: fixed;\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\nhtml, body{\r\n    overflow:hidden;\r\n}\r\n\r\n/*CLASS'es*/\r\n.hidden{\r\n    display: none !important;\r\n}\r\n\r\n.timestamp{\r\n    position: fixed;\r\n    font-size: 15px;\r\n    color: lightgray;\r\n    width: 200px;\r\n    height: 20px;\r\n    line-height: 20px;\r\n    text-align: end;\r\n    left: calc(100% - 205px);\r\n    top: calc(100% - 20px);\r\n}\r\n\r\n#body-logo{\r\n    width: 250px;\r\n    height: 250px;\r\n    object-fit: contain;\r\n    position: absolute;\r\n    left: calc(50% - 125px);\r\n    top: calc(50% - 125px);\r\n    z-index: -1;\r\n}\r\n\r\n#body-spinner{\r\n    position: absolute;\r\n\r\n    top: calc(50% - 160px);\r\n    left: calc(50% - 160px);\r\n    width: 320px;\r\n    height: 320px;\r\n    color: black;\r\n    border-width: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
