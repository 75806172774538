// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#facebook{
    width: 100%;
    height: 100%;
    background-color: var(--default-color);
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:30px;
    padding: 10px;
    text-align: center;
}

#facebook-logo{
    width: 200px;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/facebook.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["\r\n\r\n#facebook{\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: var(--default-color);\r\n    display:flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap:30px;\r\n    padding: 10px;\r\n    text-align: center;\r\n}\r\n\r\n#facebook-logo{\r\n    width: 200px;\r\n    object-fit: contain;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
