console.log('serviceworker.js loaded...')

import './serviceworker.css';

export var service_worker_registration = null;

export function installServiceWorker(){
    var installed = false;
    if (installed) return;

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
        .then(function(registration) {
            service_worker_registration = registration;
            installed = true;
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state == 'installed') {
                        $('body').append('<div class="alert alert-info alert-dismissible fade show serviceworker-alert" role="alert"><div class="serviceworker-alert-info">Update available</div><button id="serviceworker-reload-button" type="button" class="btn serviceworker-alert-button">Reload</button><button type="button" class="btn serviceworker-alert-button" data-bs-dismiss="alert">Dismiss</button></div>')
                        $('#serviceworker-reload-button').on('click', function(){
                            console.log('Reload')
                            window.location.reload();
                        })

                    }
                };
            };
        })
        .catch(error => {
            console.error('Service worker registration failed:' + error);
        })
    } else {
        console.warning('Service workers are not supported.');
    }
}
