console.log('app.js loaded...')

import { onAuthStateChanged, signInAnonymously, sendEmailVerification } from 'firebase/auth';
import { getBlob, getDownloadURL, ref } from "firebase/storage";
import { FieldPath, Timestamp, addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, limit, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';
import { getFunctions, httpsCallable } from 'firebase/functions';

import isEmail from 'sane-email-validation'
import moment from 'moment';

import { app, auth, db, doLink, doSignIn, logAnalytics, messaging, storage } from './firebase.js';
import { service_worker_registration } from './serviceworker';

const stripeFunctions = getFunctions(app,'us-central1');
const customerPortal = httpsCallable(stripeFunctions, 'ext-firestore-stripe-payments-createPortalLink');


import Logo from './images/logo.png';
import Install from './images/install.svg';
import Visit from './images/visit.svg';
import Notifications from './images/notification.svg';
import Invite from './images/invite.svg';
import Purchase from './images/purchase.svg';
import Coins from './images/coins.svg';
import Logon from './images/logon.svg';
import './app.css';
import htmlinvite from './resources/htmlinvite.txt';
import plaininvite from './resources/plaininvite.txt';

const INSTALLCREDIT = 150;
const LOGONCREDIT = 100;
const NOTIFICATIONCREDIT = 100;
const INVITECREDIT = 200;
const PRICESERIE = 100
const VISITCREDIT = 25

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlfromshop = urlParams.get('fromshop')
console.log('URLFROMSHOP', urlfromshop)

function running(busy){
    if (busy){
        $('#body-spinner').removeClass('hidden')
        $('#app-navbar').addClass('hidden')
    }else{
        $('#body-spinner').addClass('hidden')
        $('#app-navbar').removeClass('hidden')
    }
}

function CelebrateNewCredit(credit, reason){
    $('body').append('<div class="alert alert-success alert-dismissible fade show bottom-alert" role="alert">Congratulations with <b>' + credit + '</b> new coin(s)<br>' + reason + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
}
function showError(msg){
    $('body').append('<div class="alert alert-danger alert-dismissible fade show bottom-alert" role="alert">' + msg + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
}
function showInfo(msg){
    $('body').append('<div class="alert alert-info alert-dismissible fade show bottom-alert" role="alert">' + msg + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
}

var usersubscription  = null;
function runapp(){
    running(true)

    let first = true;
    console.log('runapp: ' + auth.currentUser.uid)
    const userRef = doc(db, 'users', auth.currentUser.uid)
    usersubscription = onSnapshot(userRef, snapshot => doUser(snapshot))

    var notificationstatus = null
    var userData=null;
    function doUser(snapshot){
        if (!snapshot.exists())return;
        userData = snapshot.data();

        if (first){
            first = false;
            $('#app-page').append('<div id="app-container"></div>');{
            }
            $('#app-page').append('<nav id="app-navbar" class="navbar navbar-light navbar-expand justify-content-center fixed-bottom"></nav>');{
                $('#app-navbar').append('<div id="today-icon" class="app-navbar-icon"><iconify-icon icon="mdi:new-box"></iconify-icon></div>')
                $('#app-navbar').append('<div id="archive-icon" class="app-navbar-icon"><iconify-icon icon="solar:album-line-duotone"></iconify-icon></div>')
                $('#app-navbar').append('<div id="coins-icon" class="app-navbar-icon"><iconify-icon icon="game-icons:coins"></iconify-icon></div>')
                $('#app-navbar').append('<div id="misc-icon" class="app-navbar-icon"><iconify-icon icon="codicon:symbol-misc"></iconify-icon></div>')
            }
        
            $('.app-navbar-icon').on('click', function(evt){
                $('#app-container').empty();
                $('.app-navbar-icon').removeClass('app-navbar-icon-selected')
                $(evt.currentTarget).addClass('app-navbar-icon-selected')
            })
            $('#today-icon').on('click', function(evt){
                runToday();
            })        
            $('#archive-icon').on('click', function(evt){
                runArchive();
            })        
            $('#coins-icon').on('click', function(evt){
                runCoins();
            })        
            $('#misc-icon').on('click', function(evt){
                runMisc();
            })        


            if ('permissions' in navigator){
                function checknotification(state){
                    notificationstatus = state;
                    if (state == 'granted'){
                        let firsttoken = false;
                        getToken(messaging, { serviceWorkerRegistration: service_worker_registration })
                        .then(function(currentToken){

                            let data;

                            if ((userData.notifications == null) || (userData.notifications.first == null)){
                                console.log('FIRST notification.token')
                                firsttoken=true;
                                data = {
                                    "notifications.tokens": arrayUnion(currentToken),
                                    "notifications.first": Timestamp.fromDate(new Date()),
                                    credit: increment(NOTIFICATIONCREDIT)
                                };                                    
                            }else{
                                console.log('UPDATING notification.token')
                                data = {
                                    "notifications.tokens": arrayUnion(currentToken)
                                };                                    
                            }

                            return updateDoc(doc(db,'users', auth.currentUser.uid), data)
                        })
                        .then(function(){
                            if (firsttoken) {
                                CelebrateNewCredit(NOTIFICATIONCREDIT, "Because you enabled notifications")
                                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'notification', value: parseInt(NOTIFICATIONCREDIT)})

                                $('coins-icon').trigger('click')
                            }
                        })
                        .catch(error => {
                            console.error('Claiming notification Bonus: ' + error)
                        })
                    }
                }
                navigator.permissions.query({ name: 'notifications' })
                .then(function(permissionStatus){
                    permissionStatus.onchange = () => {
                        checknotification(permissionStatus.state)
                    }
                    checknotification(permissionStatus.state)
                })
            }

            if (urlfromshop == 'true') $('#coins-icon').trigger('click')
            else $('#today-icon').trigger('click')
        }

        $('.coin-status').html('<h1>You have ' + userData.credit + ' coins</h1>')

        //check anonymous to non- anonymous
        if ((userData.personal.anonymous == false) && (userData.personal.nonanonymous == null) && (userData.personal.emailverified == true)){
            updateDoc(doc(db, 'users', auth.currentUser.uid),"personal.nonanonymous", Timestamp.fromDate(new Date()), 'credit', increment(LOGONCREDIT))
            .then(function(){
                console.log('LOGON CREDIT')
                CelebrateNewCredit(LOGONCREDIT, "Because you logged in")
                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'logon', value: parseInt(LOGONCREDIT)})
            })
            .catch(error => {
                console.error('Assignment og logon ´credit: ' + error)
            })
        }

        if ((userData.personal.anonymous == false) && (userData.personal.emailverified == false)){
            sendEmailVerification(auth.currentUser)
            .then(function(){
                console.log('email verification send')
            })
            .catch(error => {
                console.error('sending email verification email: ' + error)
            })
        }
    }
    function runToday(){
        console.log('runtoday')
        logAnalytics('screen_view',{firebase_screen:'today',firebase_screen_class:'girls'})
        running(true)
        $('#app-container').empty()


        let todaysgirlurl = "";
        let todaysgirlid;
        let todaysgirldata;
        const today = moment()
        let lastgirlseen = moment(0);
        let lastgirlcreated = moment(0);

        if (userData.lastgirl){
            lastgirlseen = moment(userData.lastgirl.seen.toDate());
            lastgirlcreated = moment(userData.lastgirl.created.toDate());
        }

        let param = '>';
        if (today.isSame(lastgirlseen,'day')) param = '==';

        const girlsRef = collection(db, 'girls');
        const girlsQuery = query(girlsRef, where('created', param, lastgirlcreated.toDate()), orderBy('created', 'asc'),limit(1))
        getDocs(girlsQuery)
        .then (snapshot => {
            if (snapshot.empty) throw('User have seen all girls')

            todaysgirlid = snapshot.docs[0].id;
            todaysgirldata = snapshot.docs[0].data()

            return getBlob(ref(storage, 'girls/' + todaysgirldata.storage + '/' + '0.jpg'))
        })
        .then(blob => {
            todaysgirlurl = URL.createObjectURL( new Blob([blob], { type: "image/jpeg" }));

            let promises = []
            if (param == '>') promises.push(updateDoc(doc(db,'users', auth.currentUser.uid), new FieldPath('lastgirl', 'seen'), serverTimestamp(),new FieldPath('lastgirl', 'created'), todaysgirldata.created,'credit',increment(VISITCREDIT)))
            return Promise.all(promises)
        })
        .then(results => {
            if (param == '>') {
                CelebrateNewCredit(VISITCREDIT,'Because you stopped by')
                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'visit', value: parseInt(VISITCREDIT)})
            }
            $('#app-container').append('<div id="today-container"></div>')
            $('#today-container').append('<div class="single-image-title">' + todaysgirldata.name + '</div>')
            $('#today-container').append('<img id="todaysgirl" class="single-image" src="' + todaysgirlurl + '">')
            $('#today-container').append('<div class="single-image-subtitle">Click to see MORE...<div>')
            running(false)
            $('#today-container').on('click', function(){
                logAnalytics('select_content', {content_type: 'todaysgirl',content_id: todaysgirlid})
                if (userData.bought && userData.bought.includes(todaysgirlid)) {
                    todaysgirldata.id = todaysgirlid
                    runSerie(todaysgirldata)
                }else
                    runSeeMore(todaysgirlid,todaysgirldata);
            })
        })
        .catch(error => {
            console.error('Deciding for todays girl: ' + error)
            running(false)
        })

    }
    function runSeeMore(id, data){
        logAnalytics('screen_view',{firebase_screen:'seemore',firebase_screen_class:'girls'})


        running(true)
        $('#app-container').empty()

        let promises = [];

        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + '0.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'a.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'b.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'c.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'd.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'e.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'f.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'g.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'h.jpg')))
        promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + 'i.jpg')))

        Promise.all(promises)
        .then(results => {

            $('#app-container').append('<div id="seemore-container"></div>')
            $('#seemore-container').append('<div class="single-image-title">' + data.name + '</div>')
            $('#seemore-container').append('<div id="seemore-image-container"></div>')

            for (let i = 1; i < results.length;i++){
                const imgdata = results[i]
                const url = URL.createObjectURL( new Blob([imgdata], { type: "image/jpeg" }));
                $('#seemore-image-container').append('<img class="seemore" src="' + url + '">')
            }
            $('#seemore-container').append('<div class="single-image-subtitle">Click to see MORE...<div>')

            $('#seemore-container').on('click', function(){
                logAnalytics('begin_checkout', {currency: 'coins', value: parseInt(PRICESERIE), items:[{id:id, affiliation: 'todaysgirl'}]})
                runBuy(id, data, results[0])
            })

            running(false)
        })
        .catch(error => {
            console.error('Fetching see more girls: ' + error)
            running(false)
        })
    }    
    function runArchive(){
        logAnalytics('screen_view',{firebase_screen:'archive',firebase_screen_class:'girls'})

        running(true)
        $('#app-container').empty()

        let lastgirlcreated = moment(0);
        if (userData.lastgirl) lastgirlcreated = moment(userData.lastgirl.created.toDate());

        let girls = [];

        const girlsRef = collection(db, 'girls');
        const girlsQuery = query(girlsRef, where('created', '<=', lastgirlcreated.toDate()))
        getDocs(girlsQuery)
        .then (snapshot => {

            let promises = []

            snapshot.forEach(record => {
                let data = record.data();
                data.id = record.id;
                girls.push(data);
                promises.push(getBlob(ref(storage, 'girls/' + data.storage + '/' + '0.jpg')))
            })

            console.log(girls)
            return Promise.all(promises)
        })
        .then(results => {
            console.log(results)
            $('#app-container').append('<div id="archive-container"></div>')
            $('#archive-container').append('<div id="archive-entry-container"></div>')

            for (let i=0; i < results.length; i++){
                const imgdata = results[i]
                const url = URL.createObjectURL( new Blob([imgdata], { type: "image/jpeg" }));
                console.log(i,girls[i])
                const key = girls[i].id;
                const name = girls[i].name;

                $('#archive-entry-container').append('<div id="archive-entry' + key + '" class="archive-entry"></div>');{
                    $('#archive-entry' + key).append('<img class="archive-thumbnail" src="' + url + '">')
                    $('#archive-entry' + key).append('<div class="archive-name">' + name + '</div>')

                    $('#archive-entry' + key).on('click', function(){
                        logAnalytics('select_content', {content_type: 'archivegirl',content_id: key})
                        runSerie(girls[i])
                    })
                }
            }

            running(false)
        })
        .catch(error => {
            console.error('Reading archive: ' + error)
            running(false)
        })
    }
    function runSerie(girl){
        logAnalytics('screen_view',{firebase_screen:'serie',firebase_screen_class:'girls'})


        $('.app-navbar-icon').removeClass('app-navbar-icon-selected')
        $('#archive-icon').addClass('app-navbar-icon-selected')

        running(true)
        $('#app-container').empty()
        $('#app-container').append('<div id="serie-container"></div>')

        const datastorage = girl.storage
        const name = girl.name
        const key = girl.id

        let promises = [];

        promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '0.jpg')))

        let canBuy = false;

        console.log(userData,key)
        if (userData.bought && userData.bought.includes(key)){
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '1.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '2.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '3.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '4.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '5.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '6.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '7.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '8.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + '9.jpg')))
    
        }else{
            canBuy = true;
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'a.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'b.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'c.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'd.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'e.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'f.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'g.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'h.jpg')))
            promises.push(getBlob(ref(storage, 'girls/' + datastorage + '/' + 'i.jpg')))    
        }

        Promise.all(promises)
        .then(results => {

            $('#serie-container').append('<div class="single-image-title">' + name + '</div>')
            $('#serie-container').append('<div id="serie-carousel" class="carousel slide" data-bs-pause="false" data-bs-ride="carousel"></div>')

            $('#serie-carousel').append('<div id="serie-carousel-indicators" class="carousel-indicators"><div>');{
                for (let i = 0; i < 10; i++){
                    let active = "";
                    if (i==0) active = 'active'
                    $('#serie-carousel-indicators').append('<button type="button" data-bs-target="#serie-carousel" data-bs-slide-to="' + i + '" class="' + active + '" aria-current="true" aria-label="Slide ' + (i+1) + '"></button>')
                }
            }
            

            $('#serie-carousel').append('<div id="serie-carousel-inner" class="carousel-inner"></div>')
            for (let i = 0; i < results.length;i++){
                const imgdata = results[i]
                const url = URL.createObjectURL( new Blob([imgdata], { type: "image/jpeg" }));

                let active = "";
                if (i == 0) active = ' active'

                $('#serie-carousel-inner').append('<div class="carousel-item' + active + '"><img src="' + url + '" class="d-block serie-image"></div>')
            }

            $('#serie-carousel').append('<button class="carousel-control-prev" type="button" data-bs-target="#serie-carousel" data-bs-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span></button>')
            $('#serie-carousel').append('<button class="carousel-control-next" type="button" data-bs-target="#serie-carousel" data-bs-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span> </button>')
    
 
  
    
    
 

            if (canBuy) {
                $('#serie-container').append('<div id="single-image-subtitle" class="single-image-subtitle">Click HERE to see MORE...<div>')
                $('#single-image-subtitle').on('click', function(){
                    console.log('clicked')
                    logAnalytics('begin_checkout', {currency: 'coins', value: parseInt(PRICESERIE), items:[{id:key, affiliation: 'archive'}]})

                    runBuy(key, girl, results[0])
                })
            }

            $('#serie-carousel').carousel('cycle');

            running(false)
        })
        .catch(error => {
            console.error('Fetching see more girls: ' + error)
            running(false)
        })

    }
    function runCoins(){
        logAnalytics('screen_view',{firebase_screen:'coins',firebase_screen_class:'admin'})


        $('#app-container').empty()

        $('#app-container').append('<div id="coin-status" class="coin-status"></div>');{
            $('#coin-status').append('<h1>You have ' + userData.credit + ' coins</h1>')
        }

        $('#app-container').append('<div id="coins-container"></div>')

        $('#coins-container').append('<div class="card-deck" id="coins-entry-container"></div>')

        //INSTALL
        $('#coins-entry-container').append('<div id="coins-entry-install" class="card coins-entry"></div>');{
            $('#coins-entry-install').append('<img class="card-img-top" src="' + Install + '" alt="Card image cap">')
            $('#coins-entry-install').append('<div id="coins-entry-install-body" class="card-body"></div>');{
               
                $('#coins-entry-install-body').append('<h5 class="card-title">Installation of app (' + INSTALLCREDIT + ' coins)</h5>')
                $('#coins-entry-install-body').append('<p class="card-text">This bonus requires user to install the app on his device.</p>')
            }
            $('#coins-entry-install').append('<div id="coins-entry-install-footer" class="card-footer coins-footer"><small class="text-muted">This bonus was received ' + moment(userData.created.toDate()).local().format('MMMM Do YYYY, h:mm a') + '</small></div>');{
                $('#coins-entry-install').css('background-color','lightgrey')
            }
        }

        //VISIT
        $('#coins-entry-container').append('<div id="coins-entry-visit" class="card coins-entry"></div>');{
            $('#coins-entry-visit').append('<img class="card-img-top" src="' + Visit + '" alt="Card image cap">')
            $('#coins-entry-visit').append('<div id="coins-entry-visit-body" class="card-body"></div>');{
                
                $('#coins-entry-visit-body').append('<h5 class="card-title">View todays girl (' + VISITCREDIT + ' point/day)</h5>')
                $('#coins-entry-visit-body').append('<p class="card-text">This bonus requires user to open the app and view todays girl.</p>')
            }
            $('#coins-entry-visit').append('<div id="coins-entry-visit-footer" class="card-footer coins-footer"><small class="text-muted">This bonus is by design</small></div>');{
                $('#coins-entry-visit').css('background-color','lightgrey')
            }
        }
        
        //LOGON
        $('#coins-entry-container').append('<div id="coins-entry-logon" class="card coins-entry"></div>');{
            $('#coins-entry-logon').append('<img class="card-img-top" src="' + Logon + '" alt="Card image cap">')
            $('#coins-entry-logon').append('<div id="coins-entry-logon-body" class="card-body"></div>');{
                $('#coins-entry-logon-body').append('<h5 class="card-title">Convert guest account (' + LOGONCREDIT + ' coins)</h5>')
                $('#coins-entry-logon-body').append('<p class="card-text">This bonus requires user to logon and convert the guest account to a real account.</p>')
            }

            $('#coins-entry-logon').append('<div id="coins-entry-logon-footer" class="card-footer coins-footer"></div>');

            if (userData.personal && userData.personal.nonanonymous) $('#coins-entry-logon-footer').append('<div><small class="text-muted">This bonus was received ' + moment(userData.created.toDate()).local().format('MMMM Do YYYY, h:mm a') + '</small></div>');
            $('#coins-entry-logon-footer').append('<button id="logon-button" type="button" class="btn btn-outline-secondary coins-button"><iconify-icon icon="mdi:card-account-details-star-outline"></iconify-icon> LOGON</button>');

            function linked(){
                console.log('linked')
                runCoins()
            }
            $('#logon-button').on('click', function(){
                doLink(linked);
            })
        }

        //NOTIFICATIONS
        if ("Notification" in window){ 
            $('#coins-entry-container').append('<div id="coins-entry-notification" class="card coins-entry"></div>');{
                $('#coins-entry-notification').append('<img class="card-img-top" src="' + Notifications + '" alt="Card image cap">')
                $('#coins-entry-notification').append('<div id="coins-entry-notification-body" class="card-body"></div>');{
                    $('#coins-entry-notification-body').append('<h5 class="card-title">Allow notifications (' + NOTIFICATIONCREDIT + ' coins)</h5>')
                    $('#coins-entry-notification-body').append('<p class="card-text">This bonus requires user to allow app to send notifications to him</p>')
                }
                if (userData.notifications && userData.notifications.first){
                    $('#coins-entry-notification').append('<div id="coins-entry-notification-footer" class="card-footer coins-footer"><small class="text-muted">This bonus was received ' + moment(userData.notifications.first.toDate()).local().format('MMMM Do YYYY, h:mm a') + '</small></div>');
                    $('#coins-entry-notification').css('background-color','lightgrey')
                }else{
                    $('#coins-entry-notification').append('<div id="coins-entry-notification-footer" class="card-footer coins-footer"><button id="notifications-button" type="button" class="btn btn-outline-secondary coins-button"><iconify-icon icon="iconamoon:notification-fill"></iconify-icon> ALLOW</button></div>');

                    $('#notifications-button').on('click', function(){
                        console.log('notification button', notificationstatus)
                        if (notificationstatus == 'prompt') Notification.requestPermission()
                        else if (notificationstatus == 'denied') $('body').append('<div class="alert alert-warning alert-dismissible fade show bottom-alert" role="alert">Earlier you <strong>denied</strong> usage of notifications for GetGirls. Please search the internet on how to undo this for your browser.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>')
                    })
                }    
            }
        }

        //INVITE
        $('#coins-entry-container').append('<div id="coins-entry-invite" class="card coins-entry"></div>');{
            $('#coins-entry-invite').append('<img class="card-img-top" src="' + Invite + '" alt="Card image cap">')
            $('#coins-entry-invite').append('<div id="coins-entry-invite-body" class="card-body"></div>');{
                $('#coins-entry-invite-body').append('<h5 class="card-title">Invite NEW user (' + INVITECREDIT + ' coins)</h5>')
                $('#coins-entry-invite-body').append('<p class="card-text">This bonus requires user to invite a friend to join GetGirls. Coins are aquired when friend joins.</p>')
            }
            $('#coins-entry-invite').append('<div id="coins-entry-invite-footer" class="card-footer coins-footer"><button id="invite-button" type="button" class="btn btn-outline-secondary coins-button"><iconify-icon icon="mdi:invite"></iconify-icon> INVITE</button></div>');

            $('#invite-button').on('click', function(){
                runInvite();
            })
        }

        //PURCHASE
        $('#coins-entry-container').append('<div id="coins-entry-purchase" class="card coins-entry"></div>');{
            $('#coins-entry-purchase').append('<img class="card-img-top" src="' + Purchase + '" alt="Card image cap">')
            $('#coins-entry-purchase').append('<div id="coins-entry-purchase-body" class="card-body"></div>');{
                $('#coins-entry-purchase-body').append('<h5 class="card-title">Buy coins</h5>')
                $('#coins-entry-purchase-body').append('<p class="card-text">This option allow users to purchase coins from our online store operated bye Stripe</p>')
            }
            $('#coins-entry-purchase').append('<div id="coins-entry-purchase-footer" class="card-footer coins-footer"><button id="purchase-button" type="button" class="btn btn-outline-secondary coins-button"><iconify-icon icon="icons8:buy"></iconify-icon> PURCHASE</button></div>');

            $('#purchase-button').on('click', function(){
                runPurchase()
            })

        }

        running(false)
    }
    function runBuy(id, data,imgdata){
        logAnalytics('screen_view',{firebase_screen:'buy',firebase_screen_class:'girls'})


        const url = URL.createObjectURL( new Blob([imgdata], { type: "image/jpeg" }));

        if (PRICESERIE > userData.credit){
            $('body').prepend('<div id="buy-alert" class="alert alert-danger fade show buy-alert" role="alert"></div>')
            $('#buy-alert').append('<h4 class="alert-heading">9 pictures of ' + data.name + '</h4>')
            $('#buy-alert').append('<img id="buy-image" src="' + url + '">')
            $('#buy-alert').append('<p style="text-align:center;">The prize of a full serie is ' + PRICESERIE + ' coins<br>You currently have ' + userData.credit +' coins</p>')
            $('#buy-alert').append('<hr>')
            $('#buy-alert').append('<div id="buy-alert-panel"></div>')
            $('#buy-alert-panel').append('<button id="dismiss-button" class="btn btn-danger" type="button" data-bs-dismiss="alert">Close</button>')    
            $('#buy-alert-panel').append('<button id="buy-button" class="btn btn-danger" type="button" data-bs-dismiss="alert">Get Coins</button>')

            $('#buy-button').on('click', function(){
                $('#coins-icon').trigger('click')
            })

        }else{
            $('body').prepend('<div id="buy-alert" class="alert alert-success fade show buy-alert" role="alert"></div>')
            $('#buy-alert').append('<h4 class="alert-heading">9 pictures of ' + data.name + '</h4>')
            $('#buy-alert').append('<img id="buy-image" src="' + url + '">')
            $('#buy-alert').append('<p style="text-align:center;">The prize of a full serie is ' + PRICESERIE + ' coins<br>You currently have ' + userData.credit +' coins</p>')
            $('#buy-alert').append('<hr>')
            $('#buy-alert').append('<div id="buy-alert-panel"></div>')
            $('#buy-alert-panel').append('<button id="dismiss-button" class="btn btn-success" type="button" data-bs-dismiss="alert">Close</button>')    
            $('#buy-alert-panel').append('<button id="buy-button" class="btn btn-success" type="button" data-bs-dismiss="alert">Buy</button>')

            $('#buy-button').on('click', function(){
                updateDoc(doc(db,'users', auth.currentUser.uid),'bought', arrayUnion(id),'credit',increment(-PRICESERIE))
                .then(() => {
                    logAnalytics('spend_virtual_currency',{value:parseInt(PRICESERIE),virtual_currency_name:'coins',id:id})
                    data.id = id;
                    runSerie(data)

                })
                .catch(error => {
                    console.log('Buying serie: ' + error)
                })
            })
        }

    }
    function runInvite(){
        logAnalytics('screen_view',{firebase_screen:'invite',firebase_screen_class:'admin'})


        $('#app-container').empty()
        $('#app-container').append('<div id="invite-container"></div>')

        $('#invite-container').append('<h1>Invite friend</h1>')

        let username = "";
        if (userData.personal.displayname) username = userData.personal.displayname;
        $('#invite-container').append('<input id="own-name" type="text" class="form-control" placeholder="Write your name here, so your friend know it is you" aria-label="Own name" value="' + username + '">')


        $('#invite-container').append('<div id="input-group-email" class="input-group mb-3"></div>');{
            $('#input-group-email').append('<input id="email-of-friend" type="email" class="form-control" placeholder="Email of friend to invite" aria-label="Friend\'s Email" aria-describedby="invite-button">')
            $('#input-group-email').append(' <button class="btn btn-secondary" type="button" id="invite-button">Invite</button>')    
        }
        $('#invite-container').append('<div id="input-group-feedback" ></div>')


        $('#email-of-friend').on('keydown', function(){
            $('#email-of-friend').css('color','black')
        })

        var id = 0;
        $('#invite-button').on('click', function(){
            const email = $('#email-of-friend').val().toLowerCase();
            if (!isEmail(email)){
                $('#email-of-friend').css('color','red')
                return;
            }
            const ownname = $('#own-name').val();

            $('#email-of-friend').val('')

            id++;
            let inviteID = null;

            $('#input-group-feedback').prepend('<div class="invite" id="invite-' + id + '"><b>' + email + '</b><br><small class="text-muted">Submitted for friend request<br>Please wait...</small></div>')
            
            const invitesRef = collection(db, 'invites')
            const newInvitation = doc(invitesRef);
            const invitesQuery = query(invitesRef, where('to', '==', email))
            getDocs(invitesQuery)
            .then(snapshot => {
                if (snapshot.size != 0) {
                    throw('User already invited')
                }

                let promises = [];
                promises.push(setDoc(newInvitation,{
                    created: serverTimestamp(),
                    to: email,
                    from: auth.currentUser.uid,
                    bonus:false,
                    account:false,
                }))

                promises.push(addDoc(collection(db, 'mail'),{
                    to: email,
                    created: serverTimestamp(),
                    message: {
                        subject: 'Invitation to join GetGirls.app',
                        text: plaininvite.replace('{{{1}}}', ownname).replace('{{{2}}}', newInvitation.id),
                        html: htmlinvite.replace('{{{1}}}', ownname).replace('{{{2}}}', newInvitation.id)
                    }
                }))

                $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted">Sending invitation<br>Please wait...</small>')

                return Promise.all(promises)
            })
            .then(results => {
                console.log(results)
                inviteID = newInvitation.id;
                const mailID = results[1].id
                console.log(inviteID,mailID)
                $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted">Awaiting email confirmation<br>Please wait...</small>')
                let count = 0;
                const interval = setInterval(function(){
                    count++;
                    $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted">Awaiting email confirmation (' + count + ')<br>Please wait...</small>')

                    getDoc(doc(db,'mail',mailID))
                    .then(snapshot => {
                        if (!snapshot.exists()) throw('No record to read')
                        const data = snapshot.data();
                        console.log(data)

                        if (data.delivery){
                            if (data.delivery.state){
                                if (data.delivery.state == 'ERROR'){
                                    clearInterval(interval)    
                                    $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted"><font color="#990000">Invitation failed<br>Failed to send email</font></small>')
                                    deleteDoc(doc(db,'invites',inviteID))
                                    .catch(error => {
                                        console.error('Failed to cancel invitation: ' + inviteID)
                                    })            
                                } 
                                if (data.delivery.state == "SUCCESS"){
                                    clearInterval(interval)    
                                    $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted"><font color="#009900">Invitation succeeded<br>You will receive your bonus once your friend joins...</font></small>')
                                }                           
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Retreiving mail status on friend invite: ' + error)
                    })

                    if (count == 60){
                        clearInterval(interval)    
                        $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted"><font color="#990000">Invitation cancelled<br>Please try later...</font></small>')
                        deleteDoc(doc(db,'invites',inviteID))
                        .catch(error => {
                            console.error('Failed to cancel invitation: ' + inviteID)
                        })
                    }
                }, 1000)
            })
            .catch(error => {
                console.error('Invitation: ' + error)
                $('#invite-' + id).html('<b>' + email + '</b><br><small class="text-muted"><font color="#990000">Invitation failed<br>' + error + '</font></small>')
                if (inviteID != null){
                    deleteDoc(doc(db,'invites',inviteID))
                    .catch(error => {
                        console.error('Failed to cancel invitation: ' + inviteID)
                    })
                }
            })
        })

    }
    function runMisc(){
        logAnalytics('screen_view',{firebase_screen:'misc',firebase_screen_class:'admin'})


        $('#app-container').empty()

        $('#app-container').append('<div id="misc-container"></div>')


        $('#misc-container').append('<div id="licence-container" class="misc-container"></div>')

        $('#licence-container').append('<div class="misc-title">3rd party software</div>')
        $('#licence-container').append('<div class="misc-subtitle">This software product has been developed using below listed open source projects, libraries, source code, software, packages, modules, etc.</div>')
        $('#licence-container').append('<div id="licence-list" class="misc-list"></div>')
    
        fetch('/thirdPartyNotice.json')
        .then(result => result.json())
        .then(TPS => {
            for (let i=0; i < TPS.length; i++){
                const TP = TPS[i]
                $('#licence-list').append('<div class="misc-box licence-box" id="tps' + i + '"></div>')
                $('#tps' + i).append('<div class="misc-one-liner"><b>Name: ' + TP.name + '</b></div>')
                $('#tps' + i).append('<div class="misc-one-liner">Version: ' + TP.version + '</div>')
                $('#tps' + i).append('<div class="misc-one-liner">Author: ' + TP.author + '</div>')
                $('#tps' + i).append('<div class="misc-one-liner">licence: ' + TP.license + '</div>')
            }
        })
    
    }
    function runPurchase(){
        logAnalytics('screen_view',{firebase_screen:'purchase',firebase_screen_class:'admin'})


        running(true)
        $('#app-container').empty()

        $('#app-container').append('<div id="purchase-status" class="coin-status"></div>');{
            $('#purchase-status').append('<h1>You have ' + userData.credit + ' coins</h1>')
        }

        $('#app-container').append('<div id="purchase-container"></div>')

        $('#purchase-container').append('<div class="card-deck" id="purchase-entry-container"></div>')

        const productsRef = collection(db, 'products')
        const productsQuery = query(productsRef, where('active','==', true))

        let products = new Map();
        getDocs(productsQuery)
        .then(snapshot => {
            let promises = []
            snapshot.forEach(product => {
                const id = product.id
                let data = product.data();
                data.prices = new Map();
                console.log('Product',id,data)
                products.set(id,data);
                promises.push(getDocs(collection(db, 'products', id, 'prices')))
            })

            return Promise.all(promises)
        })
        .then(results => {
            console.log(results)
            for (let i = 0; i < results.length; i++){
                const snapshot = results[i];

                snapshot.forEach(price => {
                    const id = price.id;
                    const data = price.data();
                    console.log('Price',id,data)

                    let product = products.get(data.product)
                    if (product){
                        console.log('adding price')
                        product.prices.set(id,data)
                    }

                })
            }

            console.log(products)

            for (let [productid, productdata] of products) {

                let sorted = new Map([...productdata.prices.entries()].sort((a, b) => a[1].unit_amount - b[1].unit_amount));

                for (let [priceid, pricedata] of sorted){

                    if (pricedata.active){
                        $('#purchase-entry-container').append('<div id="purchase-entry' + priceid + '" class="card purchase-entry"></div>');{
                            $('#purchase-entry' + priceid).append('<img class="card-img-top" src="' + Coins + '" alt="Card image cap">')
                            
                            $('#purchase-entry' + priceid).append('<div id="purchase-entry-body' + priceid + '" class="card-body"></div>');{
                                $('#purchase-entry-body' + priceid).append('<h5 class="card-title">' + productdata.name + ' for ' + (pricedata.unit_amount/100) + '$</h5>')
                                $('#purchase-entry-body' + priceid).append('<h6 class="card-subtitle mb-2 text-muted">' + productdata.description + '</h6>')
                            }
                            $('#purchase-entry' + priceid).append('<div id="purchase-entry-footer' + priceid + '" class="card-footer purchase-footer"></div>');{
                                $('#purchase-entry-footer' + priceid).append('<button id="buy-button' + priceid + '" type="button" id="buy-button' + priceid + '" class="btn btn-outline-secondary purchase-button"><iconify-icon icon="icons8:buy"></iconify-icon> Buy</button>')
    
                                $('#buy-button' + priceid).on('click', function(){
                                    logAnalytics('begin_checkout', {currency: 'usc', value: parseInt(pricedata.unit_amount), items:[{id:priceid,affiliation:'Stripe'}]})
                                    addDoc(collection(db,'customers',auth.currentUser.uid,'checkout_sessions'),{
                                        mode: "payment",
                                        price: priceid, 
                                        success_url: process.env.API_ROOT + '?fromshop=true',
                                        cancel_url: process.env.API_ROOT + '?fromshop=true',
                                    })
                                    .then(result => {
                                        console.log('ok',result)
                                        running(true)
                                        $('#app-container').empty()
                                        //wait for URL and then relocate
                                        const checkoutRef = doc(db, 'customers',auth.currentUser.uid,'checkout_sessions',result.id)
                                        let subscription = onSnapshot(checkoutRef, snapshot => {
                                            if (snapshot.exists()){
                                                const data = snapshot.data();
                                                if (data.url){
                                                    if (data.url != ""){
                                                        subscription();
                                                        window.location.replace(data.url);
                                                    }
                                                }
                                            }
                                        })
                                    })
                                    .catch(error => {
                                        console.error('Creating checkout session: ' + error)
                                        showError(error)
                                    })
                                })
                            }
                        }
    
                    }
                }
            }

            running(false)

        })
        .catch(error => {
            console.error('Retreiving products: ' + error)
            running(false)
            showError(error)
        })


    }
}

export function startapp(){
    
    function lookatinvites(){
        const invitesRef = collection(db, 'invites')

        const bonusQuery = query(invitesRef, where('from','==', auth.currentUser.uid),where('account','==', true), where('bonus','==', false))
        getDocs(bonusQuery)
        .then(snapshot => {
            console.log(snapshot.size)
            let promises = []

            snapshot.forEach( invite => {
                promises.push(updateDoc(doc(db, 'invites', invite.id), 'bonus', true))
                promises.push(updateDoc(doc(db,'users',auth.currentUser.uid), 'credit', increment(INVITECREDIT)))
            })

            return Promise.all(promises)
        })
        .then(results => {
            let pts = 0;
            for (let i = 0; i < results.length; i++){
                pts += parseInt(INVITECREDIT);
            }
            if (pts > 0) {
                CelebrateNewCredit(pts,'Because your invitation was accepted')
                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'invitation', value: pts})
            }
        })
        .catch(error => {
            console.error('Retreiving bonus: ' + error)
        })

        /*
        if (auth.currentUser.email){
            const logonQuery = query(invitesRef, where('to','==', auth.currentUser.email),where('account','==', false),where('from','!=', auth.currentUser.uid))
            getDocs(logonQuery)
            .then(snapshot => {
                console.log(snapshot.size)
                let promises = []
    
                snapshot.forEach( invite => {
                    promises.push(updateDoc(doc(db, 'invites', invite.doc), 'account', true))
                })
    
                return Promise.all(promises)
            })
            .catch(error => {
                console.error('Confirming invite: ' + error)
            })
    
        }
        */
    }
    function lookatpayments(){
        const paymentsRef = collection(db, 'customers',auth.currentUser.uid, 'payments')
        let pts = 0;
        getDocs(paymentsRef)
        .then(snapshot => {
            console.log('PAYMENTS: ' + snapshot.size)
            let promises = []
            snapshot.forEach( payment => {
                const data = payment.data();
                console.log('PAYMENT', data)
                if ((data.creditedtoapp != true) && (data.status == 'succeeded')){
                    logAnalytics('purchase',{currency:'usc', transaction_id: payment.id, value: data.amount,items:[{item_id:data.items[0].price.id}]})
                    promises.push(updateDoc(doc(db, 'customers',auth.currentUser.uid, 'payments', payment.id), 'creditedtoapp', true))
                    let points = parseInt(data.items[0].price.nickname)
                    let receipt = data.charges.data[0].receipt_url
                    console.log(receipt)
                    pts += points;
                    promises.push(updateDoc(doc(db,'users',auth.currentUser.uid), 'credit', increment(points)))
                }
            })

            return Promise.all(promises)
        })
        .then(results => {
            if (pts > 0) {
                CelebrateNewCredit(pts,'From your purchases')
                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'purchase', value: pts})
            }
        })
        .catch(error => {
            console.error('Retreiving purchases: ' + error)
        })
    }
    function restart(){
        $('#app-page').empty();
        $('#app-page').append('<div class="timestamp">' + BUILD_INFO.time + '</div>')
    
        $('#app-page').append('<div id="start-container"></div>');
        $('#start-container').append('<img id="start-container-logo" src="' + Logo + '">')
        $('#start-container').append('<h3>Creating guest account<br>please wait...</h3>')
        $('#start-container').append('<button id="logon-button" type="button" class="btn">I already have an account</button>')
    
        function saveUser(newuser = false){
            running(true)
            let docData = {
                lastlogin               :   Timestamp.fromDate(new Date()),
                created                 :   new Date(auth.currentUser.metadata.creationTime),
                personal: {
                    displayname         :   auth.currentUser.displayName,
                    email               :   auth.currentUser.email,
                    photourl            :   auth.currentUser.photoURL,
                    phone               :   auth.currentUser.phoneNumber,
                    emailverified       :   auth.currentUser.emailVerified,
                    anonymous           :   auth.currentUser.isAnonymous,    
                },
                tracking:{
                    visits              :   increment(1),
                    version             :   BUILD_INFO.time,
                },
            }
    
            if (newuser){
                docData.credit = INSTALLCREDIT;
                CelebrateNewCredit(INSTALLCREDIT,'Because you installed GetGirls')
                logAnalytics('earn_virtual_currency',{virtual_currency_name: 'installation', value: parseInt(INSTALLCREDIT)})
            }
    
            setDoc(doc(db,'users',auth.currentUser.uid),docData,{merge:true})
            .then(result => {
                console.log('USER record saved in DB - Let\'s begin')
                runapp();
                lookatinvites();
                lookatpayments();
            })
            .catch((error) => {
                console.error('Save of user: ' + error)
                running(false)
            });
        }
        function createUser(){
            running(true)
            signInAnonymously(auth)
            .then(() => {
                console.log('User Created: ' + auth.currentUser.uid)
                saveUser(true)
            })
            .catch((error) => {
                console.error('CREATE of user: ' + error)
                running(false)
            });
        }
        function firebasedone(){
            if (auth.currentUser != null){
                console.log('User Signed In: ' + auth.currentUser.uid)
                saveUser();
            }else{
                restart();
            }
        }
        
        $('#logon-button').on('click', function(){
            clearInterval(startupinterval)
            doSignIn(firebasedone);
            $('#start-container').remove();
        })
    
        let startcounter = 5;
        let startupinterval = setInterval(function(){
            if (auth.currentUser != null){
                console.log('User Returned: ' + auth.currentUser.uid)
                clearInterval(startupinterval)
                saveUser();
                $('#start-container').remove();
            }
            startcounter--;
            if (startcounter == 0) {
                clearInterval(startupinterval)
                createUser();
                $('#start-container').remove();
            }
        }, 1000)    
    }

    if (usersubscription != null) usersubscription();
    usersubscription = null;
    console.log('create APP page')
    $('body').append('<div id="app-page"></div>');
    restart();
}
export function stopapp(){
    if (usersubscription != null) usersubscription();
    usersubscription = null;
    console.log('remove APP page')
    $('#app-page').remove();
}
onAuthStateChanged(auth, async user => {
    if (user) {
        console.log('LOGON', user)
    } else {
        console.log('LOGOFF')
    }
});

